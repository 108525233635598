import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import { ContentfulMetadata } from "../../../interfaces";

export const fragment = graphql`
  fragment Metadata on ContentfulMetadata {
    title
    description {
      description
    }
    ogTitle
    ogDescription {
      ogDescription
    }
    ogImage {
      fixed {
        src
      }
    }
    twitterTitle
    twitterDescription {
      twitterDescription
    }
    twitterImage {
      fixed {
        src
      }
    }
  }
`;

interface MetaProps extends ContentfulMetadata {
  meta?: ContentfulMetadata;
  defaults?: ContentfulMetadata;
}

const Meta = ({ meta, defaults }: MetaProps) => {
  return (
    <Helmet>
      <title>{meta.title || defaults.title}</title>
      <meta
        name="description"
        content={
          meta.description
            ? meta.description.description
            : defaults.description.description
        }
      />
      <meta property="og:title" content={meta.ogTitle || defaults.ogTitle} />
      <meta
        property="og:description"
        content={
          meta.ogDescription
            ? meta.ogDescription.ogDescription
            : defaults.ogDescription.ogDescription
        }
      />
      <meta
        property="og:image"
        content={
          (meta.ogImage && meta.ogImage.fixed.src) || defaults.ogImage.fixed.src
        }
      />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={meta.twitterTitle || defaults.twitterTitle}
      />
      <meta
        name="twitter:description"
        content={
          meta.twitterDescription
            ? meta.twitterDescription.twitterDescription
            : defaults.twitterDescription.twitterDescription
        }
      />
      <meta
        name="twitter:image"
        content={
          (meta.twitterImage && meta.twitterImage.fixed.src) ||
          defaults.twitterImage.fixed.src
        }
      />
    </Helmet>
  );
};

export default Meta;
